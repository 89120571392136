import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class SettingsTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "featureColumn", title: "Feature" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { featureColumn: 'Mobile Mode', descriptionColumn: 'The mobile mode will grab snapshots from the camera in a fixed interval instead of the h.264 live stream. This reduces the needed bandwidth and keep a connection alive even if the 3G/LTE connection is weak for the high resolution live stream. It also reduces the data volume dramatically.' },
        { featureColumn: 'Image Preview', descriptionColumn: 'Use the refresh time to adjust the image refresh rate in the Multiview window of the app. You can also define how many cameras are displayed per screen, of the video aspect ratio is to be maintained, the screen is prevented from going to sleep and if you want to stop the image refresh when your mobile device switches to a mobile network (3G, LTE...).' },
        { featureColumn: 'Video Options', descriptionColumn: 'These options will affect the full screen Camera View. Define if you want the app to maintain the aspect ratio or fill the screen with the live video, if the screen is to be kept alive and if you want to use an advanced video renderer for high resolution devices.' },
        { featureColumn: 'Audio', descriptionColumn: 'You can set the app to increase the playback volume automatically, when you want to listen to a camera`s live audio stream.' },
        { featureColumn: 'Data & Sync', descriptionColumn: 'The Camera View allows you to set several camera functions, e.g. the Motion Detection. But the app won`t check if those functions have been changed by another user. To sync all your camera settings inside the app please push the corresponding button. The import / export function allows you store your app configuration (all added cameras) and restore them - e.g. to transfer the settings to another device.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

SettingsTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(SettingsTable)