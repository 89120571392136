import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from '@elastic/eui'

export default function ImageWall() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="s" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign='left'
              paddingSize='l'
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/Settings/ImageWall/InstarVision_Android_Settings_01.webp'
                  alt='Add your camera using its P2P address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Camera Settings'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        Open the Settings menu by selecting the Settings button from the side menu.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign='left'
              paddingSize='m'
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/Settings/ImageWall/InstarVision_Android_Settings_02.webp'
                  alt='Add your camera using its P2P address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Select a Camera'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                          Here you first have to select the camera you want to access.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/Settings/ImageWall/InstarVision_Android_Settings_03.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Settings Overview'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                            Once selected you will see the overview menu that lists all categories with adjustable items.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/Settings/ImageWall/InstarVision_Android_Settings_04.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Connection Settings'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          These are the settings you made when adding your camera. Come here if you changed your camera's address or login.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/Settings/ImageWall/InstarVision_Android_Settings_05.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='User Management'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          Edit existing camera users or add new users with different access rights.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/Settings/ImageWall/InstarVision_Android_Settings_06.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Alarm Schedule'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          Edit the schedule your camera uses to activate the alarm actions.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>

        <EuiSpacer />

        <EuiFlexItem>
          <EuiFlexGroup gutterSize="l" direction="row" >
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/Settings/ImageWall/InstarVision_Android_Settings_07.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Alarm Settings'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          Adjust your camera's alarm actions that will be triggered in case of an alarm event.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/Settings/ImageWall/InstarVision_Android_Settings_08.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Alarm Areas'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          Adjust the alarm areas used by your camera's software based motion detection.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/Settings/ImageWall/InstarVision_Android_Settings_09.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Wifi Setup'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          Start a wizard that guides you though the process of adding your camera to a WiFi network.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/Settings/ImageWall/InstarVision_Android_Settings_10.webp'
                  alt='Add your camera using its P2P address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Email Settings'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        Adjust the SMTP settings your camera uses to send notification emails.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/Settings/ImageWall/InstarVision_Android_Settings_11.webp'
                  alt='Add your camera using its P2P address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='PTZ Settings'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        If your camera has pan&tilt or zoom functionality you can make adjustments for it here.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/Settings/ImageWall/InstarVision_Android_Settings_12.webp'
                  alt='Add your camera using its P2P address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='System Reboot'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        Click on System Reboot to manually reboot your camera.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}